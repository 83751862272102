import axios from "axios";
import { store } from "../../index";
import { FETCH_DATA_POPUP,POPUP_SUCCESS,POPUP_FAIL } from './shared'
export const popUpAction = () => async (dispatch) => {
  dispatch({
    type: FETCH_DATA_POPUP,
  });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/pop_ups?find[is_enabled]=true`,
      {
        headers: {
          "Content-Language": `${store.getState().lang}`,
        },
      }
    );
    dispatch({ type: POPUP_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: POPUP_FAIL, payload: error.message });
  }
};
