import React, { useRef } from "react";
import styles from "../skiSchool.module.scss";
import SwiperSlider from "../../General/swiper/swiper";
import { Container } from "@mui/material";
import useLocale from "../../../lang/useLocale";
// import { Grid} from '@mui/material'

export default function LessonTutorial(props) {
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  const settings = {
    loop: false,
    spaceBetween: 20,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1025: {
        slidesPerView: 2,
      },
    },
    arrows: true,
  };
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   if (props.data) {
  //     const { data } = props.data;
  //     if (Array.isArray(data)) {
  //       setData(data);
  //       console.log(data);
  //     }
  //   }
  // }, [props.data, data]);
  // console.log(props.data);
  return (
    <div className={styles.lessonTutorial} id="swiperTutorial">
      <Container maxWidth="lg">
        <h4 className={styles.title}>{getLocale("LESSON TUTORIALS")}</h4>
        <SwiperSlider settings={settings} mySwiper={mySwiper}>
          {props.data?.plugin?.map((lesson, index) => {
            return (
              <div
                key={index}
                className={`${styles.packageSlide} swiper-slide`}
              >
                <iframe
                  title="video"
                  width="100%"
                  height="280"
                  src={lesson.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            );
          })}
        </SwiperSlider>
      </Container>
    </div>
  );
}
