import { combineReducers } from "redux";
import lang from "./lang";
import size from "./size";
import fb from "./fb";
import ig from "./ig";

import {
  activityListReducer,
  listOptionsReducer,
  filteredDataReducer,
} from "./skiSchoolReducers";
import { popUpReducer } from "./popUpReducer";
import { webReducer } from "./webReducers";
const rootReducer = combineReducers({
  lang,
  size,
  fb,
  ig,
  activityListReducer,
  listOptionsReducer,
  filteredDataReducer,
  webReducer,
  popUpReducer
});

export default rootReducer;
