import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import rootReducer from "./redux/reducers";
import middlewares from "./redux/middlewares";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancer(middlewares));

const containerStyles = {
  maxWidth: {
    xs: '100%',
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const theme = createTheme({
  spacing: 24, // This works for spacing utilities
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          // Apply responsive maxWidth using the predefined styles
          maxWidth: containerStyles.maxWidth.xs,
          '@media (min-width:600px)': {
            maxWidth: containerStyles.maxWidth.sm,
          },
          '@media (min-width:900px)': {
            maxWidth: containerStyles.maxWidth.md,
          },
          '@media (min-width:1200px)': {
            maxWidth: containerStyles.maxWidth.lg,
          },
          '@media (min-width:1536px)': {
            maxWidth: containerStyles.maxWidth.xl,
          },
        },
      },
    },
  },
});

// Use createRoot instead of ReactDOM.render
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
