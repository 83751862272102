import React, { useState, useRef, useEffect } from "react";
import { Container, Grid, Collapse, Button } from "@mui/material";
import styles from "./crystalPackages.module.scss";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";
import SwiperSlider from "../../General/swiper/swiperTest";
import dicountOnGroupLesson from '../../../img/discount-on-group-lessons.jpg';
import dicountOnSki from '../../../img/discount-on-ski.jpg';
import dicountOnSnowClassic from '../../../img/discount-on-snow-classic.jpg';
import slopeAccess from '../../../img/slope-access.jpg';
import freePremiumRental from '../../../img/free-premium-rental.jpg';
import groupLessonsInvitations from '../../../img/group-lessons-invitaions.jpg';
import snowClassicInvitaion from '../../../img/snow-classic-invitaions.jpg';
import penguinEncounter from '../../../img/penguin-encounter.jpg';
import { useForm } from "react-hook-form";
import axios from "axios";
import loaderImg from "../../../img/loader.gif";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../../General/popup/popup.scss";

function CrystalPackages() {
  const lang = useSelector((state) => state.lang);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  const myElement = useRef();
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [bookName, setBookName] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);

  // Define the `settings` object for SwiperSlider
  const settings = {
    arrows: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  useEffect(() => {
    if (myElement.current) {
      const myElementCurrent = myElement.current;

      const handleScroll = () => {
        const nextButton = myElementCurrent.querySelector('.swiper-button-next');
        const prevButton = myElementCurrent.querySelector('.swiper-button-prev');

        // Check if the buttons exist before accessing their classList
        if (nextButton && prevButton) {
          const top = myElementCurrent.getBoundingClientRect().top;
          const bottom = myElementCurrent.getBoundingClientRect().bottom;

          if (top <= 300 && bottom >= 450) {
            nextButton.classList.add("active");
            prevButton.classList.add("active");
          } else {
            nextButton.classList.remove("active");
            prevButton.classList.remove("active");
          }
        }
      };

      // Attach the scroll event listener
      window.addEventListener("scroll", handleScroll);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [myElement]);

  const handleClickOpen = (e) => {
    setBookName(e.target.name);
    setOpen(!open);
  };

  const onSubmit = (data) => {
    setDisabled(true);
    setLoader(true);
    if (error === true) {
      setError(false);
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/slopememberships`, { ...data, package_name: bookName })
      .then((response) => {
        window.dataLayer.push({ event: "Successful Form Submission", "Form Name": `Slope Memberships ${bookName}` });
        setLoader(false);
        setSubmittingForm(true);
        setTimeout(() => hidePopUp(), 15000);
        reset({});
        setOpen(false);
      })
      .catch((error) => {
        setLoader(false);
        setError(true);
      });
  };

  const hidePopUp = () => {
    setSubmittingForm(false);
    setDisabled(false);
  };

  return (
    <Container className={styles.crystalPackages}>
      {/* Start grid for title */}
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <div className={styles.subTitle}><p>{getLocale("PROFESSIONALS")}</p></div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.title}><p>{getLocale("Slope Memberships")}</p></div>
        </Grid>
      </Grid>
      {/* End grid for title */}

      {/* Start grid for table */}
      <Grid container spacing={1} direction={lang === "en" ? "row" : "row-reverse"} justifyContent="center" alignItems="center">
        <Grid item className={styles.reverse} xs={6} md={4}>
          <div className={styles.benefits}>
            <h6>{getLocale("Benefits")}
              <br />
              <Button
                type="button"
                color="primary"
                style={{ color: '#ff173d', padding: "0", textDecoration: "underline" }}
                onClick={() => setCollapse(state => !collapse)}
              >
                {collapse ? getLocale('Collapse') : getLocale('Expand')}
              </Button>
            </h6>
          </div>

          <Collapse in={collapse}>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="slopeAccess" src={slopeAccess} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Slope Access")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="invitaion" src={groupLessonsInvitations} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Group Lessons invitations")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="penguinEncounter" src={penguinEncounter} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Penguin Encounter invitations")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="classicInvitaion" src={snowClassicInvitaion} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Snow Classic Invitations")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="premiumRental" src={freePremiumRental} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Free Premium Rental (Vouchers)")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="ski" src={dicountOnSki} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Discount on Ski & Snowboard gear")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="groupLesson" src={dicountOnGroupLesson} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Discount on Group Lessons")}</p></div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.card}>
              <Grid item xs={5}>
                <div><img alt="snowClassic" src={dicountOnSnowClassic} /></div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.imgTilte}><p>{getLocale("Discount on Snow Classic")}</p></div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid xs={6} md={8} item ref={myElement}>
          <SwiperSlider settings={settings} mySwiper={mySwiper} containerClass={styles.swiperContainerClass}>
            {/* Basic Package */}
            <div className={`${styles.platinumHeader} swiper-slide`}>
              <div className={`${styles.header} ${styles.grayColor}`}>
                <p className={styles.title}>{getLocale("Basic")}<span>({getLocale(`1 month`)})</span></p>
                <p className={`${styles.price} ${styles.red}`}>{getLocale(`1,199 L.E`)}</p>
                <a href={"#."} name={getLocale("Basic")} onClick={handleClickOpen} className={`${styles.btn} ${styles.btnRed}`}>
                  {getLocale("book")}
                </a>
              </div>
              <Collapse in={collapse}>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("Unlimited")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("25% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("25% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("15% OFF")}</span></p>
                  </div>
                </div>
              </Collapse>
            </div>

            {/* Silver Package */}
            <div className={`${styles.platinumHeader} swiper-slide`}>
              <div className={`${styles.header} ${styles.grayColor}`}>
                <p className={styles.title}>{getLocale("Silver")}<span>({getLocale(`3 months`)})</span></p>
                <p className={`${styles.price} ${styles.red}`}>{getLocale(`2,899 L.E`)}</p>
                <a href={"#."} onClick={handleClickOpen} name={getLocale("Silver")} className={`${styles.btn} ${styles.btnRed}`}>{getLocale("book")}</a>
              </div>
              <Collapse in={collapse}>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("Unlimited")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={`${styles.title} ${styles.red}`}><span>{getLocale("Notavailable")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("25% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("25% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("15% OFF")}</span></p>
                  </div>
                </div>
              </Collapse>
            </div>

            {/* Gold Package */}
            <div className={`${styles.platinumHeader} swiper-slide`}>
              <div className={`${styles.header} ${styles.grayColor}`}>
                <p className={styles.title}>{getLocale("Gold")}<span>({getLocale(`6 months`)})</span></p>
                <p className={`${styles.price} ${styles.red}`}>{getLocale(`4,499 L.E`)}</p>
                <a href={"#."} onClick={handleClickOpen} name={getLocale("Gold")} className={`${styles.btn} ${styles.btnRed}`}>{getLocale("book")}</a>
              </div>
              <Collapse in={collapse}>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("Unlimited")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("3 Invitations")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("3 Invitations")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("3 Invitations")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("6 Vouchers")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("30% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("50% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("20% OFF")}</span></p>
                  </div>
                </div>
              </Collapse>
            </div>

            {/* Platinum Package */}
            <div className={`${styles.platinumHeader} swiper-slide`}>
              <div className={`${styles.header} ${styles.redColor}`}>
                <p className={styles.title}>{getLocale("Platinum")}<span>({getLocale(`12 months`)})</span></p>
                <p className={styles.price}>{getLocale("6,699 L.E")}</p>
                <a href={"#."} onClick={handleClickOpen} name={getLocale("Platinum")} className={`${styles.btn} ${styles.btnGray}`}>{getLocale("book")}</a>
              </div>
              <Collapse in={collapse}>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("Unlimited")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("5 Invitations")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("5 Invitations")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("5 Invitations")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("9 Vouchers")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("30% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("50% OFF")}</span></p>
                  </div>
                </div>
                <div className={`${styles.cardItem} ${styles.grayColor}`}>
                  <div>
                    <p className={styles.title}><span>{getLocale("25% OFF")}</span></p>
                  </div>
                </div>
              </Collapse>
            </div>
          </SwiperSlider>
        </Grid>
      </Grid>
      {/* End grid for table */}

      {/* Dialog for booking */}
      <Dialog
        open={open}
        onClose={handleClickOpen}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        className="containerPopup"
      >
        <DialogTitle id="form-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <div className="contactFooter">
            <p className={`${lang === "en" ? "LEFT" : "RTL"}`}>
              {`${lang === "en" ? "Book Now" : "احجز الان"} - ${bookName}`}
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`${lang === "en" ? "LEFT" : "RTL"}`}
            >
              <Grid container justifyContent="space-between" className="form">
                <Grid item xs={12}>
                  <input
                    {...register("first_name", { required: true })}
                    type="text"
                    placeholder={`${lang === "en" ? "*First Name" : "الأسم الأول*"}`}
                  />
                  {errors.first_name && errors.first_name.type === "required" && (
                    <div className="error">This field is required</div>
                  )}
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <input
                    {...register("last_name", { required: true })}
                    type="text"
                    placeholder={`${lang === "en" ? "*Last Name" : "الأسم الأخير*"}`}
                  />
                  {errors.last_name && errors.last_name.type === "required" && (
                    <div className="error">This field is required</div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    placeholder={`${lang === "en" ? "*Phone Number" : "رقم الهاتف*"}`}
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <div className="error">This field is required</div>
                  )}
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    placeholder={`${lang === "en" ? "*Email Address" : "البريد الألكتروني*"}`}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <div className="error">This field is required</div>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} className="containerBtnSubmit">
                  <button disabled={disabled} type="submit">
                    {`${lang === "en" ? "Submit" : "ارسال"}`}
                  </button>
                </Grid>
                {loader && (
                  <div className="loader">
                    <img src={loaderImg} alt="loader" />
                  </div>
                )}
                {submittingForm && (
                  <Grid item xs={12} className="containerBtnSubmit">
                    <div className="thankYou">{getLocale("Thank you!")}</div>
                  </Grid>
                )}
                {error && (
                  <Grid item xs={12} className="containerBtnSubmit">
                    <div className="errorOccurred">{getLocale("errorOccurred")}</div>
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default CrystalPackages;
