import React from "react";
import styles from "./tabsForGroupPackages.module.scss";
import { AppBar, Tabs, Tab } from "@mui/material";
import useLocale from "../../../../lang/useLocale";
import CorporateBenefits from "../corporateBenefits/corporateBenefits";
// import corporateBenefitsOne from "../../../../img/corporatebenefitsone.png";
// import corporateBenefitsTwo from "../../../../img/corporatebenefitstwo.png";
// import schoolTripsOne from "../../../../img/school-trips-one.png";
// import schoolTripsTwo from "../../../../img/school-trips-two.png";
// import birthday from "../../../../img/Birthday.png";
// import BirthdayParties from "../birthdayParties/birthdayParties";
import { Container } from "@mui/material";

export default function TabsForGroupPackages(props) {
  const { getLocale } = useLocale();
  const [value, setValue] = React.useState(0);
  // console.log(props.data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log(props.data?.plugin)
  return (
    <div className={styles.TabsForGroupPackagesContainer}>
      <AppBar position="static" className={`${styles.MuiAppBar}`}>
        <Container>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab
              selected
              className={`${styles.MuiTab}`}
              label={getLocale("Corporate Benefits")}
            />
            <Tab label={getLocale("School Trips")} />
            {/* <Tab label={getLocale("Team Building")} /> */}
            <Tab label={getLocale("Birthday Parties")} />
          </Tabs>
        </Container>
      </AppBar>
      {props.data?.plugin?.map((plug) => {
        return (
          value === plug.id - 1 && (
            <React.Fragment key={plug.id}>
              <CorporateBenefits
                imgOne={plug.image}
                imgTwo={plug.image2}
                title={plug.title}
                paragraph={plug.description}
                param={plug.seo_id}
              />
            </React.Fragment>
          )
        );
      })}
      {/* {value === 1 && (
        <React.Fragment>
          <CorporateBenefits
            imgOne={schoolTripsOne}
            imgTwo={schoolTripsTwo}
            title={getLocale("SCHOOL TRIP PACKAGES")}
            paragraph={getLocale("SCHOOL TRIP PACKAGES Content")}
            param="schoolTrips-popup"
          />
        </React.Fragment>
      )} */}
      {/* {value === 2 &&
                <React.Fragment>
                    <CorporateBenefits
                        imgOne={teamBuildingOne}
                        imgTwo={teamBuildingTwo}
                        title={getLocale("TEAM-BUILDING ACTIVITIEs")}
                        paragraph={getLocale("teamBuildingActivitiesContentTwo")}
                    />
                </React.Fragment>
            } */}
      {/* {value === 2 && (
        <React.Fragment>
          <BirthdayParties
            img={birthday}
            title={getLocale("Birthday Group Packages")}
            paragraph={getLocale("BirthdayPartiesContent")}
            param="birthdayParties-popup"
          />
        </React.Fragment>
      )} */}
    </div>
  );
}
