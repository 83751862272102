import React, { useEffect, useRef, useState } from "react";
import Packages from "./subOffers/Packages/packages";
import HeaderPanel from "../General/headerPanel/headerPanel";
import styles from "./offers.module.scss";
// import SocialSection from "../General/social-section/social-section";
import useLocale from "../../lang/useLocale";
import { Container, Grid, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { SkiClubProgram } from "../skiSchool/subComponents/skiClubProgram/skiClubProgram";
import { Helmet } from "react-helmet";
import leftArrow from "../../img/left-arrow.svg";
import close from "../../img/close-white.svg";
import styles2 from "../offers/subOffers/GridPackages/GridPackages.module.scss";
import backgroundSki from "../../img/dogs-experience-bg.jpg";
function Offers() {
  const { getLocale } = useLocale();
  const lang = useSelector((state) => state.lang);

  //   const dispatch = useDispatch();
  const dataOffers = useSelector((state) => state.webReducer);
  const [offersData, setOffersData] = useState([]);
  const [form, setForm] = useState({});
  const [offersPlugins, setOffersPlugins] = useState([]);
  const { data } = dataOffers;


  useEffect(() => {
    if (Array.isArray(data)) {
      setOffersData(data[6]);
      setOffersPlugins(data[6].sections[1].plugin);
      setForm(data[6].sections[2])
    }
  }, [data, offersData]);
  const [open, setOpen] = useState({});
  const modalRef = useRef([]);
  const handleOpen = (i) => {
    // console.log(i, modalRef.current[i].dataset.modal, open[i][i])
    setOpen((prev) => ({ ...prev, [i]: true }));
    // console.log(modalRef.current[i], offersPlugins, open)
  };
  useEffect(() => {
    let obj = offersPlugins.map((item, key) => { return { [key]: false } })
    obj = obj.reduce(function (result, item) {
      var key = Object.keys(item)[0]; //first property: a, b, c
      result[key] = item[key];
      return result;
    }, {});
    setOpen(obj)
  }, [offersPlugins])



  const handleClose = () => {
    // console.log(open);
    // let k = {
    //   ...Object.keys(open).reduce((reduced, key) => ({ ...reduced, [key]: false }), {})
    // }
    // console.log(k)
    setOpen((prev) => {
      return {
        ...Object.keys(prev).reduce((reduced, key) => ({ ...reduced, [key]: false }), {})
      }
    })

  };

  // Screen size from redux
  const size = useSelector((state) => state.size);

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "Enjoy snow park offers, hurry up book tickets online & get special offers. Enjoy ice skating, skiing, click here to know more about exclusive offers."
          )}
        />
        <title>
          {getLocale(
            "Hurry up! to Enjoy Offers & Special Discounts | Ski Egypt"
          )}
        </title>
        <link rel="canonical" href={`https://skiegy.com/${lang}/offers`} />
      </Helmet>
      <div>
        {offersPlugins.map((plug, key) => {
          return (
            <Modal key={plug.id} open={open[key] === undefined ? false : open[key]}
              onClose={handleClose}>
              <div className={`${styles2.containerGridAll} ${styles2.modal}`}>
                <img
                  className={styles2.close}
                  onClick={handleClose}
                  src={close}
                  alt="img"
                />
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  className={`${styles2.containerData}`}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <div className={styles2.header2}>
                    <h2>{plug.title}</h2>
                    <p>{plug.description}</p>
                  </div>
                  {/* <Grid item xs={12} className={`${styles2.Header}`}>
                    <p>{getLocale("All for only 395 EGP")}</p>
                  </Grid> */}
                  {/* <Grid item xs={12} className={`${styles2.description}`}>
                    <ul>
                      <li>{getLocale("Free hot chocolate")}</li>
                      <li>{getLocale("Free locker")}</li>
                    </ul>
                  </Grid> */}
                  {/* <Grid
                    container
                    item
                    xs={12}
                    className={`${styles2.PackagePerPerson}`}
                  >
                    <Grid item className={`${styles2.Header}`}>
                      <p>{getLocale("Valid till 31/3/2021")}</p>
                    </Grid>
                  </Grid> */}
                  <Grid container item xs={12} className={`${styles2.description}`}>
                    <Grid item xs={6} md={4}>
                      <a
                        href={plug.url}
                        target="_self"
                        rel="noopener noreferrer"
                      >
                        {getLocale("BOOK NOW")}
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          )
        })}


      </div>

      <HeaderPanel
        panal={size >= 900 ? offersData.image : offersData.responsive_image}
        title=""
        description=""
        hideCardTitle
        className="gif"
      />
      <Container className={styles.offerssubZero}>
        <h2>{offersData.title}</h2>
        <p>{offersData.description}</p>
      </Container>
      <Container className={styles.content}>
        <div className={styles.heading}>
          <h6>{data && data[6]?.sections[1]?.subtitle} </h6>
          <h4>{data && data[6]?.sections[1]?.title}</h4>
        </div>
        <Grid container spacing={1}>
          {data && data[6]?.sections[1]?.plugin.map((plug, i) => {
            return (
              <React.Fragment key={i}>
                <Grid item md={12} lg={4} ref={el => modalRef.current[i] = el} data-modal={i}>
                  <div className={styles.card} onClick={handleOpen.bind(null, i)}>
                    <img src={plug.image} alt="img" />
                    <div className={styles.gradient}></div>
                    <div>
                      <p>{plug.title}</p>
                      <img src={leftArrow} alt="img" />
                    </div>
                  </div>
                </Grid>
              </React.Fragment>
            )
          }
          )}
        </Grid>
      </Container>
      {form?.visibility === 1 &&
        <SkiClubProgram
          animation={true}
          background={backgroundSki}
          data={form}
          price={getLocale("250")}
          link={true}
          enURL={"https://booking.skiegy.com/en/snow-park/booking/snow-dogs"}
          arURL={"https://booking.skiegy.com/ar/snow-park/booking/snow-dogs"}
        />}

      <Packages
        data={Array.isArray(offersData.sections) && offersData.sections[0]}
      />
      {/* <SocialSection /> */}
    </div>
  );
}

export default Offers;
