import { FETCH_DATA_POPUP,POPUP_SUCCESS,POPUP_FAIL } from '../actions/shared'
export const popUpReducer = (state = { loading: true, dataPopUp: {} }, action) => {
  switch (action.type) {
    case FETCH_DATA_POPUP:
      return { loading: true };
    case POPUP_SUCCESS:
      return { loading: false, dataPopUp: action.payload };
    case POPUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
