import React from "react";
import styles from "./corporateBenefits.module.scss";
import { Container, Grid } from "@mui/material";
import ContactUs from "../contactUs/contactUs";
import { useSelector } from "react-redux";

export default function CorporateBenefits(props) {
  const browserLang = useSelector((state) => state.lang);
  return (
    <div className={styles.corporateBenefitsContainer}>
      <Container>
        <Grid
          container
          spacing={props.imgTwo ? 1 : 0}
          justifyContent="space-between"
          style={{ textAlign: browserLang === "ar" ? "right" : "left" }}
        >
          <Grid item md={props.imgTwo ? 3 : 6} xs={6}>
            <img src={props.imgOne} alt="" />
          </Grid>
          {props.imgTwo && (
            <Grid item md={3} xs={6}>
              <img src={props.imgTwo} alt="" />
            </Grid>
          )}

          <Grid item md={props.imgTwo ? 6 : 5}>
            <h3>{props.title}</h3>
            <p>{props.paragraph}</p>
            <ContactUs subject={props.title} param={props.param} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
