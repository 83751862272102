import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PopupTab(props) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p>{props.question}</p>
      </AccordionSummary>
      <AccordionDetails>
        <p>{props.answer}</p>
      </AccordionDetails>
    </Accordion>
  );
}
