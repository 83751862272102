import React, { useRef, useLayoutEffect } from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function SwiperComponent(props) {
  const containerRef = useRef(null);
  const swiperInstanceRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const bulletsRef = useRef(null);

  const {
    arrows,
    search,
    breakpoints,
    spaceBetween,
    autoplay,
    autoplayDisableOnInteraction,
    slidesPerView,
    loop,
    bullets,
  } = props.settings;

  // Initialize Swiper
  useLayoutEffect(() => {
    if (containerRef.current) {
      swiperInstanceRef.current = new Swiper(containerRef.current, {
        modules: [Navigation, Pagination, Autoplay],
        breakpoints: breakpoints || {},
        spaceBetween: spaceBetween || 0,
        autoplay: autoplay || false,
        autoplayDisableOnInteraction: autoplayDisableOnInteraction || false,
        slidesPerView: slidesPerView || 1,
        loop: loop || false,
        navigation: arrows
          ? {
              nextEl: nextRef.current,
              prevEl: prevRef.current,
            }
          : false,
        pagination: bullets
          ? {
              el: bulletsRef.current,
              type: "bullets",
              clickable: true,
            }
          : false,
      });

      // Pass the Swiper instance to the parent component
      if (props.mySwiper) {
        props.mySwiper.current = swiperInstanceRef.current;
      }
    }

    // Cleanup Swiper on unmount
    return () => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
      }
    };
  }, [breakpoints, spaceBetween, autoplay, autoplayDisableOnInteraction, slidesPerView, props.mySwiper, arrows, bullets, loop]);

  // Update Swiper when children change
  useLayoutEffect(() => {
    if (swiperInstanceRef.current && props.children) {
      swiperInstanceRef.current.update(); // Call update on the Swiper instance
    }
  }, [props.children]);

  return (
    <div className={`swiper ${props.containerClass || ""}`} ref={containerRef}>
      <div className="swiper-wrapper">{props.children}</div>
      {bullets && <div className="swiper-pagination" ref={bulletsRef}></div>} {/* Restored bulletsRef */}
      {arrows && (
        <>
          <div className="swiper-button-prev" ref={prevRef}></div>
          <div className="swiper-button-next" ref={nextRef}></div>
        </>
      )}
      <div className="swiper-scrollbar"></div>
      {search}
    </div>
  );
}

SwiperComponent.defaultProps = {
  settings: {},
};
