import React from "react";
import styles from "./card.module.scss";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocale from "../../../lang/useLocale";

export default function ZoomedCard({ href, imageUrl, title, description }) {
    const { getLocale } = useLocale();
    const lang = useSelector((state) => state.lang);

    return (
        <Grid item xs={12} sm={6} md={3}>
            <div className={styles.card}>
                <div className={styles.imageWrapper}>
                    <img src={imageUrl} alt="background" className={styles.image} />
                </div>
                <div className={styles.content}>
                    <h4 className={styles.title}>{title}</h4>
                    <div className={styles.hoverContainer}>
                        <p className={styles.description}>{description}</p>
                        <Link className={styles.linkStyle} to={`/${lang}${href}`}>
                            {getLocale("learnmore")}
                        </Link>
                    </div>
                </div>

            </div>
        </Grid>
    );
}
