import React, { useState } from "react";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./header.module.scss";
// import tiktok from "../../img/tik-tok.svg";
// import searchIcon from '../../img/search.svg'
// import snow from '../../img/snow.svg'
import { useDispatch, useSelector } from "react-redux";
import { handleLang } from "../../redux/actions/lang";
import tiktok from "../../img/tik-tok.svg";

// import useLocale from '../../lang/useLocale'
// import { Link } from 'react-router-dom'
import CustomSelect from "../select/select";
function TopBar(props) {
  const browserLang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  let location = useLocation();

  let navigate = useNavigate();
  const changeLang = (e) => {
    setLang(e.currentTarget.dataset.value);
    dispatch(handleLang(e.currentTarget.dataset.value));
    document.cookie = `lang=${e.currentTarget.dataset.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure`;
    let url = '/' + e.currentTarget.dataset.value + location.pathname.substring(3);
    navigate(url)
    // console.log(e.currentTarget.dataset.value)
  };
  // eslint-disable-next-line
  const [lang, setLang] = useState("en");
  if (props.size > 1199) {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={styles.topBar}
      >
        <Grid item xs={6} className={styles.socialIcons}>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/skiegypt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/ski.egypt/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCggXDtcjumJo_b_Rex5xnOg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@ski.egypt?is_copy_url=1&is_from_webapp=v1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ width: "22px" }} src={tiktok} alt="tiktok icon" />
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <CustomSelect
                name="lang"
                className={styles.langSelct}
                options={[
                  { value: "en", label: "ENGLISH" },
                  { value: "ar", label: "العربية" },
                ]}
                placeholder={browserLang === "ar" ? "العربية" : "ENGLISH"}
                changeHandler={changeLang}
              // soon
              />
            </Grid>
            <Grid item className={styles.topBarRight}>
              {/* <ul>
                <li>
                  <a href="https://www.tiktok.com/@ski.egypt?is_copy_url=1&is_from_webapp=v1">
                    <img style={{width:"22px"}} src={tiktok} alt="phone icon" />
                  </a>
                </li>
              </ul> */}
            </Grid>
            <Grid item className={styles.snow}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="center"
                className={styles.boxwrap}
              >
                <Grid item className={styles.degree}>
                  <span>{props.degree} &#8451;</span>
                </Grid>
                <Grid item className={styles.time}>
                  {props.time}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

export default TopBar;
