import React, { useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import styles from './assets/select.module.scss'

function Select(props) {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")
  const [label, setLabel] = useState("")

  function handleSelect(e) {
    if (props.soon) {
      alert('This feature will be available soon')
    } else {
      setLabel(e.currentTarget.dataset.label)
      setValue(e.currentTarget.dataset.value)
      // to excute change handler if exists
      if (props.changeHandler) {
        props.changeHandler(e)
      }
    }
  }

  function handleMenu() {
    setOpen(prevLabel => !prevLabel)
  }

  function closeMenu() {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <div className={`${styles.selectContainer} ${props.className}`} onClick={handleMenu}>
        <input name={props.name} value={value} type="hidden" />
        <input type="text" value={label} disabled placeholder={props.placeholder ? props.placeholder : null} />
        <ul className={`${styles.containerOptions} ${open ? styles.open : ''}`} >
          {props.options.map(option => {
            return <li key={option.value} data-value={option.value} data-label={option.label} onClick={handleSelect}>{option.label}</li>
          })}
        </ul>
      </div>
    </ClickAwayListener>
  )
}

export default Select