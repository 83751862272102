import React, { useEffect, useRef, useState } from "react";
import styles from "./skiSchool.module.scss";
import Banner from "../General/headerPanel/headerPanel";
// import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions';
// import HeaderText from "../offers/subOffers/HeaderText/HeaderText";
// import AlpineGrid from "../offers/subOffers/GridPackages/AlpineGrid";
import School from "./subComponents/school";
import Video from "../../components/General/videoBasic/video";
import MeetTrainers from "./subComponents/meetTrainers";
import ProPackages from "./subComponents/proPackages";
import LessonTutorial from "./subComponents/lessonTutorial";
import useLocale from "../../lang/useLocale";
// import PackageCard from "../General/packageCard/packageCard";
// import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";

// import PopUpEidAdha from "../Home/SubComponents/popUpEidAdha/popUpEidAdha";
// import packagesImg1 from "../../img/freestyle-pass-package.png";
// import packagesImg2 from "../../img/slope-pass-packages.jpg";
// import { SkiClubProgram } from "./subComponents/skiClubProgram/skiClubProgram";
// import {EidAdha} from './subComponents/eidAdha/eidAdha';
import { Helmet } from "react-helmet";
// import {
//   pageHeaderSkiSchool,
//   sectionSkiSchool,
//   trainersActions,
//   lessonTutorialActions,
// } from "../../redux/actions/skiSchoolActions";
// import PopUpEidAdha from "../Home/SubComponents/popUpEidAdha/popUpEidAdha";

export default function SkiSchool() {
  // const dispatch = useDispatch();
  const dataSkiSchool = useSelector((state) => state.webReducer);
  const [skiSchoolData, setSkiSchoolData] = useState([]);
  const { data } = dataSkiSchool;
  // console.log(aboutData);
  useEffect(() => {
    if (Array.isArray(data)) {
      setSkiSchoolData(data[1]);
    }
  }, [data, skiSchoolData]);
  // const pageHeader = useSelector((state) => state.pageHeaderSkiSchoolReducer);
  // const { data } = pageHeader;
  // const section = useSelector((state) => state.sectionSkiSchoolReducer);
  // const trainers = useSelector((state) => state.trainersReducer);
  // const lessonTutorials = useSelector((state) => state.lessonTutorialReducer);
  // const alpinePackages = useSelector((state) => state.alpinePackagesReducer);
  const schoolRef = useRef();
  const { getLocale } = useLocale();
  const [clicked, setClicked] = useState(false);
  const lang = useSelector((state) => state.lang);
  // console.log(data);
  // const chooseYourLesson = () => {
  //   setClicked(true);
  // schoolRef.current.getBoundingClientRect();
  // console.log(schoolRef.current.getBoundingClientRect())
  // };
  // console.log(section.data && section.data[0]);
  useEffect(() => {
    if (clicked) {
      schoolRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setClicked(false);
    }
    // console.log(clicked)
  }, [clicked]);
  // useEffect(()=>{
  //   console.log(alpinePackages?.data)
  // if(Array.isArray(alpinePackages?.data)){
  //  console.log(alpinePackages.data[0].description)
  // }
  // },[alpinePackages])
  return (
    <div className={styles.skiSchool}>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "Join our Ski School for skiing or snowboarding lessons at Ski Egypt with our excellent professional instructors. Visit ski Egypt now for more details & enjoy ski lesson."
          )}
        />
        <title>
          {getLocale(
            "Now in Egypt, Learn Skiing or Snowboard| Ski School|Ski Egypt"
          )}
        </title>
        <link rel="canonical" href={`https://skiegy.com/${lang}/skischool`} />
      </Helmet>
      <Banner
        panal={skiSchoolData.image}
        alt="ski egypt school"
        containerCard
        title={skiSchoolData.title}
        description={skiSchoolData.description}
      />
      <Video
        title={
          Array.isArray(skiSchoolData.sections) &&
          skiSchoolData.sections[0].title
        }
        img={
          Array.isArray(skiSchoolData.sections) &&
          skiSchoolData.sections[0].image
        }
        alt="التزحلق على الجليد"
        videoUrl={
          Array.isArray(skiSchoolData.sections) &&
          skiSchoolData.sections[0].video
        }
      // "https://www.youtube.com/embed/tPz744gFQqw"
      />

      <School
        data={
          Array.isArray(skiSchoolData.sections) && skiSchoolData.sections[1]
        }
        refI={schoolRef}
      />
      {/* <SkiClubProgram click={chooseYourLesson} /> */}
      {/* <EidAdha click={chooseYourLesson}/> */}
      {/* <div className="popUpSkiClupProgram">
				<PopUpEidAdha/>
				</div> */}

      <ProPackages />

      <MeetTrainers
        data={
          Array.isArray(skiSchoolData.sections) && skiSchoolData.sections[2]
        }
      />
      <LessonTutorial
        data={
          Array.isArray(skiSchoolData.sections) && skiSchoolData.sections[3]
        }
      />

      {/* Alpine Packages */}
      {/* <div className={styles.alpineWrap}>
        <HeaderText
          title={getLocale("PACKAGES")}
          subtitle={getLocale("Alpine Packages")}
        />
        <Container>
          <Grid container spacing={1} justifyContent="center">
            {Array.isArray(skiSchoolData.sections) &&
              skiSchoolData.sections[4]?.plugin?.map((packageItem) => {
                return (
                  <Grid key={packageItem.id} item md={4} xs={12}>
                    <PackageCard
                      img={packageItem.image}
                      alt="تزلج على الجليد"
                      title={packageItem.name}
                      list={[packageItem.description]}
                      price={packageItem.price}
                      validity="6"
                      Availability={true}
                      contact={getLocale("BOOK NOW")}
                      // contact={getLocale("Booking Unavailable")}
                      link={packageItem.book}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </div> */}
      {/* <SocialSection /> */}
    </div>
  );
}
