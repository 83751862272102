import React from "react";
import styles from "../skiSchool.module.scss";
import { Container } from "@mui/material";
import Search from "./search";
import useLocale from "../../../lang/useLocale";

export default function School(props) {
  const { getLocale } = useLocale();
  return (
    <div ref={props.refI} className={`${styles.school} skiShcool`}>
      <Container maxWidth="lg">
        <h2 className={styles.title}>{props.data.title}</h2>
        <Search data={props.data} />
        <div className={styles.note}>
          <p>{getLocale("Any additional guest")}</p>
          <p>{getLocale("Minimum age")}</p>
        </div>
      </Container>
    </div>
  );
}
