import React, {useEffect, useState, Fragment, useLayoutEffect} from "react";
import {Route, Routes, useNavigate, useLocation, Navigate} from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/Home/home";
import AboutUs from "./components/AboutUs/AboutUs";
import "./app.sass";
import loaderImg from "./img/loader.gif";
import Offers from "./components/offers/offers";
import SkiSchool from "./components/skiSchool/skiSchool";
// import SummerCamp from "./components/SummerCamp/summerCamp";
import Professionals from "./components/Professionals/professionals";
import Penguins from "./components/penguins/penguins";
import Packages from "./components/packages/packages";
// import ChristmasPackage from "./components/christmasPackage/christmasPackage";
import {useDispatch} from "react-redux";
import {handlefbFetchData} from "./redux/actions/fb";
import {handleigFetchData} from "./redux/actions/ig";
import {handleLang} from "./redux/actions/lang";
// import Bowser from "bowser";
import {Helmet} from "react-helmet";
import GroupPackages from "./components/GroupPackages/groupPackages";
import useLocale from "./lang/useLocale";
import {useSelector} from "react-redux";
import {activityListActions} from "./redux/actions/skiSchoolActions";

import {webAction} from "./redux/actions/webActions";
import {popUpAction} from "./redux/actions/popUpAction";
import SkiSchoolLanding from "./components/SkiSchoolLanding/SkiSchoolLanding";
// import CorporateBenefits from "./components/GroupPackages/corporateBenefits/corporateBenefits";
// import DayTrip from "./components/GroupPackages/dayTrip/dayTrip";
// import TeamBuilding from "./components/GroupPackages/teamBuilding/teamBuilding";
// import BirthdayParties from "./components/GroupPackages/birthdayParties/birthdayParties";
// import Landing from './components/landing/landing'

function App() {
  //this is a new hook from react router that have multiple properties like pathname, hash, search or state etc
  const {pathname} = useLocation();
  const {getLocale} = useLocale();
  const lang = useSelector(state => state.lang);
  const navigate = useNavigate();
  const location = useLocation();
  const [redirect, setRedirect] = useState("/en/");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);
  // to ensure data from FB api is ready after document is loaded and then dispatch redux
  useEffect(() => {
    let intervalfb = setInterval(intervalfbFunc, 1500);
    function intervalfbFunc() {
      if (document.readyState === "complete" && window._fbData) {
        clearInterval(intervalfb);
        dispatch(handlefbFetchData(window._fbData));
      }
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let intervalig = setInterval(intervaligFunc, 1500);
    function intervaligFunc() {
      if (document.readyState === "complete" && window._igData) {
        clearInterval(intervalig);
        dispatch(handleigFetchData(window._igData));
      }
    }
    // if (lang==='ar') {
    //   setRedirect('ar')
    //   history.push(`/ar${location.pathname.substring(3)}`)
    //   // history.push(`/ar}`)
    // }
    if (lang === "ar") {
      setRedirect("ar");
      navigate(`${location.pathname.replace("/en/", "/ar/")}`);
      // history.push(`/ar}`)
    }
    if (window.location.href.includes("/en/")) {
      dispatch(handleLang("en"));
    }
    if (window.location.href.includes("/ar/")) {
      dispatch(handleLang("ar"));
    }
    // eslint-disable-next-line
  }, [lang]);
  // useEffect(() => {
  //   if (document.cookie.includes("lang=ar")) {
  //     dispatch(handleLang("ar"));
  //   }
  //     }, [dispatch,redirect]);
  useLayoutEffect(() => {
    // const browser = Bowser.getParser(window.navigator.userAgent);
    // let browserName = browser.getBrowser().name;
    // document.documentElement.className = browserName;
  }, []);
  useEffect(() => {
    if (lang) {
      Promise.all([
        dispatch(webAction()),
        dispatch(popUpAction()),
        dispatch(activityListActions())
      ]);
    }
  }, [dispatch, lang]);
  return (
    <Fragment>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="g90u9sbpigmtvarxrkugd5qiyw4ulx"
        />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content={getLocale(
            "Ski Egypt is the biggest snow park in Africa that includes the first and only destination of penguins in Egypt , for you to meet and greet those little ones whenever you visit, in addition to the first ski school in Egypt."
          )}
        />
        <title>{getLocale("SKI EGYPT")}</title>
        <link rel="canonical" href={`https://skiegy.com/${lang}/`} />
      </Helmet>
      {loader ? (
        <div className="loader">
          <img src={loaderImg} alt="loader" />
        </div>
      ) : null}
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Navigate to={redirect} />} />
            <Route path={`/${lang}/`} element={<Home />} />
            <Route path={`/${lang}/aboutus`} element={<AboutUs />} />
            <Route path={`/${lang}/prices`} element={<Packages />} />
            <Route path={`/${lang}/groupPackages`} element={<GroupPackages />} />
            <Route path={`/${lang}/offers`} element={<Offers />} />
            <Route path={`/${lang}/skischool`} element={<SkiSchool />} />
            <Route path={`/${lang}/professionals`} element={<Professionals />} />
            <Route path={`/${lang}/penguins`} element={<Penguins />} />
            <Route
              path={`/${lang}/snow-world-day`}
              element={<SkiSchoolLanding />}
            />
            {/*<Route path={"/" + lang + "/summer-camp"} component={SummerCamp} />*/}
            {/* <Route path="/packages" component={Packages} /> */}
            {/* <Route path={"/" + lang + "/christmaspackage"} component={ChristmasPackage} /> */}
            {/* <Route path="/corporateBenefits" component={CorporateBenefits} />
          <Route path="/dayTrip" component={DayTrip} />
          <Route path="/teamBuilding" component={TeamBuilding} />
          <Route path="/birthdayParties" component={BirthdayParties} /> */}
            {/* rollback */}

          </Routes>
        </main>
        <Footer />
      </div>
    </Fragment>
  );
}

export default App;
