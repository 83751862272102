import React, { useState, Fragment } from "react";
import styles from "../home.module.scss";
import { Container, Grid } from "@mui/material";
// import videoImg from "../../../img/video.jpg";
import play from "../../../img/play.svg";
// import useLocale from "../../../lang/useLocale";

export default function Video(props) {
  const [placeHolder, setPlaceHolder] = useState(true);
  //   console.log(props.data);
  function handlePlaceHolder() {
    setPlaceHolder(!placeHolder);
  }
  //   const { getLocale } = useLocale();
  return (
    <div className={styles.video}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12} md={6} style={{ position: "relative" }}>
            {placeHolder ? (
              <Fragment>
                <img
                  src={props.data.image}
                  alt="video"
                  onClick={handlePlaceHolder}
                  className={styles.placeHolder}
                />
                <img
                  src={play}
                  alt="play"
                  className={styles.play}
                  onClick={handlePlaceHolder}
                />
              </Fragment>
            ) : (
              <iframe
                title="video"
                width="100%"
                height="376"
                src={props.data.video}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <h4>{props.data.title}</h4>
            <h2>{props.data.subtitle}</h2>
            <p>
              {props.data.description}
              {/* <br />
              <br />
              {getLocale("gettoknow2")}
              <br />
              <br />
              {getLocale("gettoknow3")}
              <br />
              <br />
              {getLocale("gettoknow4")} */}
            </p>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}
