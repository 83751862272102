import styles from "./PopUp.module.scss";
import React, { useRef } from "react";
import Popup from "../../../General/popup/popup";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import close from "../../../../img/close-white.svg";

const PopUp = ({data}) => {
  const size = useSelector((state) => state.size);
  const btnRef = useRef();
  const lang = useSelector((state) => state.lang);


  return (
    <div className={styles.PopUpContainer} ref={btnRef}>
      <Popup
        popupTitle=""
        popupTriggerTitle={data.title ? data.title : ""}
        closePopupStyle={`${
          lang === "en" ? "closePopupLeft" : "closePopupRight"
        }`}
        imgClose={size <= 768 && close}
      >
        <div id="popUpWrapper">
          <Grid container direction={lang === "en" ? "row" : "row-reverse"}>
            <Grid item md={6} xs={12}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  padding:
                    lang === "en" ? "10px 0 10px 10px" : "10px 0px 10px 0px",
                }}
                src={data.image}
                alt="skiClup"
              />
            </Grid>
            <Grid item md={6} xs={12} className="bgContent">
              <div className="popupContentContainer">
                <p className="subTitle">{data.subtitle}</p>
                <h3 className="title">{data.title}</h3>
                <p className="content">
                  {data.description}
                </p>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item md={7} xs={7}>
                    <button className="registerBtn">
                      <a
                        href={data.button_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.button_title}
                      </a>
                    </button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Popup>
    </div>
  );
};
export default PopUp;
