import React, { useState, Fragment, useEffect } from "react";
import styles from "./footer.module.scss";
import { Container, Grid } from "@mui/material";
import logo from "../../img/logo-alfuttaim-footer.png";
import logoSki from "../../img/logo.svg";
import blueArrow from "../../img/blueArrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import useLocale from "../../lang/useLocale";
import { Link } from "react-router-dom";
import Popup from "../General/popup/popup";
import { useSelector } from "react-redux";
import Rules from "./rules/rules";
import PopupTab from "../General/popup/popUpTab";
import { useForm } from "react-hook-form";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import axios from "axios";
import loaderImg from "../../img/loader.gif";
import flakeIcon from "../../img/flake-icon.png";

function Footer() {
  const { getLocale } = useLocale();
  const lang = useSelector((state) => state.lang);
  const [explore, setExplore] = useState(false);
  const [links, setLinks] = useState(false);
  const [support, setSupport] = useState(false);
  const [legal, setLegal] = useState(false);
  const [working, setWorking] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [error, setError] = useState(false);
  const [closePopUp, setClosePopUp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  let hideTimeOut;

  // Debug: Ensure Mailchimp URL is valid
  const mailchimpUrl = process.env.REACT_APP_MAILCHIMP_URL || "";
  console.log("Mailchimp URL:", mailchimpUrl);

  const onSubmit = (data) => {
    setDisabled(true);
    setLoader(true);
    if (error === true) {
      setError(false);
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/contactus`, data)
      .then((response) => {
        setLoader(false);
        setSubmittingForm(true);
        hideTimeOut = setTimeout(() => hidePopUp(), 15000);
        reset({});
      })
      .catch((error) => {
        setLoader(false);
        setError(true);
      });
  };

  const hidePopUp = () => {
    setSubmittingForm(false);
    setDisabled(false);
  };

  useEffect(() => {
    if (submittingForm === false) {
      setClosePopUp(true);
    }
    clearTimeout(hideTimeOut);
  }, [submittingForm, hideTimeOut]);

  const openCookieSetting = () => {
    window.OneTrust.ToggleInfoDisplay();
  };

  return (
    <Fragment>
      <footer>
        <Container className={styles.firstSection}>
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={10}
              justifyContent="space-between"
              alignItems="center"
              className={`${styles.subscribe}`}
              direction={lang === "en" ? "row" : "row-reverse"}
            >
              <Grid item xs={12} md={5} className={`${styles.sectionHeader}`}>
                <p>{getLocale("Subscribe to newsletter")}</p>
                <span>{getLocale("stayupdated")}</span>
              </Grid>
              <MailchimpSubscribe
                url={mailchimpUrl}
                render={({ subscribe, status, message }) => (
                  <Grid
                    item
                    xs={12}
                    md={5}
                    className={`${styles.sectionEmailAddress}`}
                  >
                    <form
                      id="subscribe"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const emailInput = e.currentTarget.children[0].value;
                        subscribe({ EMAIL: emailInput });
                      }}
                    >
                      <input
                        type="text"
                        name="EMAIL" // Ensure this is a valid string
                        placeholder={`${lang === "en" ? "Email Address" : "البريد الألكتروني"}`}
                      />
                      <button form="subscribe" type="submit">
                        {getLocale("submit")}
                      </button>
                    </form>
                    {status === "sending" && (
                      <div style={{ color: "blue" }}>sending...</div>
                    )}
                    {status === "error" && (
                      <div style={{ color: "red" }}>{message}</div>
                    )}
                    {status === "success" && (
                      <div style={{ color: "green" }}>Subscribed !</div>
                    )}
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </Container>
        <div className={`${styles.secondSection}`}>
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              direction={lang === "en" ? "row" : "row-reverse"}
            >
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.firstColumn}
              >
                <img src={logoSki} alt="logo" />
                <p>{getLocale("ultimatedestination")}</p>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  alignItems="center"
                  className={`${styles.sectionSocial}`}
                  spacing={1}
                  justifyContent={lang === "ar" ? "center" : null}
                >
                  <Grid item xs={3}>
                    <a
                      href="https://www.facebook.com/skiegypt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href="https://www.instagram.com/ski.egypt/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href="https://www.youtube.com/channel/UCggXDtcjumJo_b_Rex5xnOg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href="https://www.tiktok.com/@ski.egypt?is_copy_url=1&is_from_webapp=v1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTiktok} />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.secondColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={() => setExplore(!explore)}
                  >
                    <p className={explore ? "chevron" : null}>
                      {getLocale("explore")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>
                  <Grid
                    container
                    className={`${styles.sectionData} ${!explore ? "collapse" : ""}`}
                  >
                    <Grid item xs={12}>
                      <Link to={"/" + lang + "/"} rel="canonical">
                        {getLocale("home")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={"/" + lang + "/aboutus"} rel="canonical">
                        {getLocale("ABOUT")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={`/${lang}/skischool`}>
                        {getLocale("SKI SCHOOL")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={`/${lang}/penguins`}>
                        {getLocale("PENGUINS")}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.secondColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={() => setLinks(!links)}
                  >
                    <p className={links ? "chevron" : null}>
                      {getLocale("quicklinks")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>
                  <Grid
                    container
                    className={`${styles.sectionData} ${!links ? "collapse" : ""}`}
                  >
                    <Grid item xs={12}>
                      <Link to={`/${lang}/offers`} rel="canonical">
                        {getLocale("OFFERS")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={`/${lang}/prices`} rel="canonical">
                        {getLocale("PRICES")}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.thirdColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={() => setSupport(!support)}
                  >
                    <p className={support ? "chevron" : null}>
                      {getLocale("help")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>
                  <Grid
                    container
                    className={`${styles.sectionData} ${!support ? "collapse" : ""}`}
                  >
                    <Grid item xs={12} className="privacyPolicy">
                      <a
                        href={`https://privacy-center-dev.majidalfuttaim.com/client/2d5739a0-dda3-4b4a-a445-c058ae6dd4eb?lang=${lang}&country=egy`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLocale("Privacy Center")}
                      </a>
                    </Grid>
                    <Grid item xs={12} className="privacyPolicy">
                      <a href="javascript:void(0)" style={{ cursor: 'pointer' }} onClick={openCookieSetting}>
                        {getLocale('Cookie settings')}
                      </a>
                    </Grid>
                    <Grid item xs={12}>
                      <Popup
                        hideClose
                        popupTitle=""
                        popupTriggerTitle={`${lang === "en" ? "FAQs" : "الأسئلة الاكثر شيوعًا"}`}
                        popupTriggerClass="rules"
                      >
                        <div className={`${lang === "en" ? "popupFaq" : "popupFaqAr"}`}>
                          <h2>{getLocale("FAQs")}</h2>
                          <PopupTab
                            question={getLocale("Q_FAQs1")}
                            answer={getLocale("A_FAQs1")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs2")}
                            answer={getLocale("A_FAQs2")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs3")}
                            answer={getLocale("A_FAQs3")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs4")}
                            answer={getLocale("A_FAQs4")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs5")}
                            answer={getLocale("A_FAQs5")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs6")}
                            answer={getLocale("A_FAQs6")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs7")}
                            answer={getLocale("A_FAQs7")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs8")}
                            answer={getLocale("A_FAQs8")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs9")}
                            answer={getLocale("A_FAQs9")}
                          />
                        </div>
                      </Popup>
                    </Grid>
                    <Grid item xs={12}>
                      <Popup
                        dialogWidth="sm"
                        popupTitle={`${lang === "en" ? "Contact Us" : "تواصل معنا"}`}
                        popupTriggerTitle={`${lang === "en" ? "Contact Us" : "تواصل معنا"}`}
                        popupTriggerClass="contact"
                        onSubmit={closePopUp}
                        imgClose={flakeIcon}
                        closePopupStyle={`${lang === "en" ? "closePopupStyleLEFT" : "closePopupStyleRTL"}`}
                      >
                        <div className="contactFooter">
                          <p className={`${lang === "en" ? "LEFT" : "RTL"}`}>
                            {`${lang === "en" ? "Happy to hear from you" : "تواصل معنا"}`}
                          </p>
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={`${lang === "en" ? "LEFT" : "RTL"}`}
                          >
                            <Grid container justifyContent="space-between" className="form">
                              <Grid item xs={12}>
                                <input
                                  {...register("first_name", { required: true })}
                                  type="text"
                                  placeholder={`${lang === "en" ? "*First Name" : "الأسم الأول*"}`}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ textAlign: "left" }}>
                                <input
                                  {...register("last_name", { required: true })}
                                  type="text"
                                  placeholder={`${lang === "en" ? "*Last Name" : "الأسم الأخير*"}`}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <input
                                  {...register("phone", { required: true })}
                                  type="text"
                                  placeholder={`${lang === "en" ? "*Phone Number" : "رقم الهاتف*"}`}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ textAlign: "left" }}>
                                <input
                                  {...register("email", { required: true })}
                                  type="email"
                                  placeholder={`${lang === "en" ? "*Email Address" : "البريد الألكتروني*"}`}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <textarea
                                  {...register("message", { required: true })}
                                  placeholder={`${lang === "en" ? "*Message" : "الرسالة*"}`}
                                />
                              </Grid>
                              <Grid container justifyContent="center">
                                <Grid item xs={12} className="containerBtnSubmit">
                                  <button disabled={disabled} type="submit">
                                    {`${lang === "en" ? "Submit" : "ارسال"}`}
                                  </button>
                                </Grid>
                                {loader && (
                                  <div className="loader">
                                    <img src={loaderImg} alt="loader" />
                                  </div>
                                )}
                                {submittingForm && (
                                  <Grid item xs={12} className="containerBtnSubmit">
                                    <div className="thankYou">
                                      {getLocale("Thank you!")}
                                    </div>
                                  </Grid>
                                )}
                                {error && (
                                  <Grid item xs={12} className="containerBtnSubmit">
                                    <div className="errorOccurred">
                                      {getLocale("errorOccurred")}
                                    </div>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </form>
                        </div>
                      </Popup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.thirdColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={() => setLegal(!legal)}
                  >
                    <p className={legal ? "chevron" : null}>
                      {getLocale("legal")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>
                  <Grid
                    container
                    className={`${styles.sectionData} ${!legal ? "collapse" : ""}`}
                  >
                    <Grid item xs={12} className="antiFraud">
                      <a
                        href="https://www.majidalfuttaim.com/en/anti-fraud-disclaimer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLocale("Anti-Fraud Disclaimer")}
                      </a>
                    </Grid>
                    <Grid item xs={12}>
                      <Popup
                        popupTitle=""
                        popupTriggerTitle={`${lang === "en" ? "Terms & Conditions" : "الشروط والأحكام"}`}
                        popupTriggerClass="rules"
                      >
                        <div id="rulesPopup">
                          <Grid container item xs={12}>
                            <Grid item className="containerHeader">
                              <p>{`${lang === "en" ? "Terms & Conditions" : "الشروط والأحكام"}`}</p>
                            </Grid>
                            <Grid item className="containerData">
                              <ul className="containerUL">
                                <Rules lang={lang} />
                              </ul>
                            </Grid>
                          </Grid>
                        </div>
                      </Popup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.thirdColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={() => setWorking(!working)}
                  >
                    <p className={working ? "chevron" : null}>
                      {getLocale("working hours")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionData} ${!working ? "collapse" : ""}`}
                  >
                    <Grid container item xs={12}>
                      <Grid item>
                        <span>{getLocale("10 AM - 10 PM")}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={`${styles.containercontainerCopyright}`}>
          <Container maxWidth="xl" className={`${styles.containerCopyright}`}>
            <Grid
              container
              spacing={1}
              direction={lang === "en" ? "row" : "row-reverse"}
              alignItems="center"
            >
              <Grid container item xs={12} md={3} alignItems="center">
                <div className={`${styles.sectionBrandName}`}>
                  <img src={logo} alt="logo" />
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                alignItems="center"
                justifyContent="center"
              >
                <div className={`${styles.sectionBrandTitle}`}>
                  <span>{getLocale("majid")}</span>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={4}
                alignItems="center"
                justifyContent="center"
              >
                <div className={`${styles.sectionBrandTitle}`}>
                  <span>{new Date().getFullYear()} {getLocale("rightsreserved")}</span>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
