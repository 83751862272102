import React, { useRef } from "react";
import styles from "./meetTrainers.module.scss";
import Slider from "../../General/swiper/swiper";
import { Container } from "@mui/material";
import useLocale from "../../../lang/useLocale";
import { Grid } from "@mui/material";

export default function MeetSchoolTrainers(props) {
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  const settings = {
    arrows: true,
    bullets: false,
    loop: true,
    spaceBetween: 15,
    autoplay: {
      delay: 10000,
    },
    autoplayDisableOnInteraction: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  };
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   if (props.data) {
  //     const { data } = props.data;
  //     if (Array.isArray(data)) {
  //       setData(data);
  //     }
  //   }
  // }, [props.data, data]);
  // console.log(props.data);

  return (
    <div
      id="swiperTrainers"
      className={`${styles.ticketsPenguins} ${styles.trainers}`}
    >
      <Container maxWidth="lg">
        <div className={styles.meet}>
          <h4>{getLocale("MEET THE TRAINERS")}</h4>
        </div>
        <div id="swiperPenguinTeam">
          <Slider settings={settings} mySwiper={mySwiper}>
            {props.data?.plugin?.map((trainer) => {
              return (
                <div
                  key={trainer.id}
                  className={`swiper-slide ${styles.penguinSlide}`}
                >
                  <img src={trainer.image} alt="trainer" />
                  <h5>{trainer.name}</h5>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12} className={styles.sliderItem}>
                      <span>{trainer.title}</span>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </div>
  );
}
