import React from "react";
import styles from "./headerPanel.module.scss";
import { Container } from "@mui/material";
// import homehead from "../../../../src/img/homehead.png";
import homefoot from "../../../../src/img/homefoot.png";
// import Countdown from "react-countdown";
// import cmlogo from "../../../../src/img/cmlogo.png";

const headerPanel = (props) => {
  // const d = new Date("2023-1-1");
  const { hideSnow = false } = props
  return (
    <div className={`${styles.containerHeaderPanel}`}>
      <div className={`${styles.containerPanal} ${styles.relativePos}`}>
        {/* <div className={`${styles.positionAbsolute}`}>
          <h2>Expires in: <Countdown date={d} /></h2>
          <div className={`${styles.cmlogo}`}>
            <img src={cmlogo} />
          </div>
          <img src={homehead} alt="snow" />
        </div> */}
        <img src={props.panal} alt={props.alt ? props.alt : "panal"} className={props.className} />
        {!hideSnow &&
          <div className={`${styles.positionAbsoluteFoot}`}>
            <img src={homefoot} alt="snow" />
          </div>
        }
      </div>
      {
        props.containerTextCenter ? (
          <div className={`${styles.containerTextCenter}`}>
            <p className={`${styles.title} ${styles.titleCentered}`}>
              {props.title}
            </p>
          </div>
        ) : null
      }

      {
        props.containerCard ? (
          <Container maxWidth="lg">
            <div className={`${styles.containerTitel}`}>
              <p className={`${styles.subTitle}`}>{props.subTitle}</p>
              {!props.hideCardTitle ? (
                <h1 className={`${styles.title}`}>{props.title}</h1>
              ) : null}
              <p className={`${styles.description}`}>{props.description}</p>
            </div>
          </Container>
        ) : null
      }
    </div >
  );
};
export default headerPanel;
