import { FETCH_DATA_FB } from '../actions/shared';

const initialState = { data: [] };

export default function fb(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA_FB:
      const newData = Array.isArray(action.data?.data) ? action.data.data : [];
      const nextPage = action.data?.paging?.next || null;

      return {
        ...state,
        data: [...state.data, ...newData],
        next: nextPage,
      };
    default:
      return state;
  }
}
