import React, {useRef, useState, useEffect} from "react";
import styles from "./home.module.scss";
import Search from "./SubComponents/search";
// import Select from '../select/select'
import Swiper from "../General/swiper/swiper";
import slider1 from "../../img/slider1.jpg";
import slider2 from "../../img/slider2.jpg";
import slider3 from "../../img/slider3.jpg";
// import snowDayMobile from '../../img/mobile_snow_day.png'
// import snowDay from '../../img/snow_day.png'
import Offers from "./SubComponents/offers";
import Packages from "./SubComponents/packages";
import Save from "./SubComponents/save";
// import Save from "./SubComponents/saveNew";
// import SocialSection from "../General/social-section/social-section";
import {useSelector} from "react-redux";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions'
import Video from "./SubComponents/video";
// import newHome from "../../../src/img/santasection.png";
// import homehead from "../../../src/img/homehead.png";
// import homefoot from "../../../src/img/homefoot.png";
// import Countdown from "react-countdown";
// import cmlogo from "../../../src/img/cmlogo.png";
// import homebgnew from "../../../src/img/homebgnew.jpeg";
// import Santa from "./SubComponents/Santa/Santa";
// import useLocale from "../../lang/useLocale";
// import backgroundSki from "../../img/ski-club-program-bg.jpg";
// import { SkiClubProgram } from "../skiSchool/subComponents/skiClubProgram/skiClubProgram";
// import PopUpSkiClupProgram from "./SubComponents/popUpSkiClupProgram/popUpSkiClupProgram";
// import PopUpKingPenguin from "./SubComponents/popUpKingPenguins/popUpKingPenguins";
// import PopUpTheIceWarriorChallenge from "./SubComponents/popUpTheIceWarriorChallenge/popUpTheIceWarriorChallenge";
// import PopUpSkiPro from "./SubComponents/popUpSkiPro/PopUpSkiPro";
// import PopUpPengiuns from "./SubComponents/popUpPengiuns/popUpPengiuns";
// import PopUpRally from "./SubComponents/popUpRally/PopUpRally";
// import NewOffer from "./SubComponents/New Offer/newOffer";
import PopUp from "./SubComponents/PopUp/PopUp";

function Home() {
  const mySwiper = useRef(null);
  // const [clicked, setClicked] = useState(false);
  const popupRef = useRef();
  // const schoolRef = useRef();

  // const chooseYourLesson = () => {
  //   setClicked(true);
  //   schoolRef.current.getBoundingClientRect();
  //   // console.log(schoolRef.current.getBoundingClientRect())
  // };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (popupRef.current) {
        // popupbtn.querySelector("button").click();
        const popupBtn = popupRef.current.querySelector("button");
        popupBtn.click();
        // console.log(popupbtn.querySelector("button"));
        popupRef.current.click();
      }
    }, 2000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, []);
  const dataHome = useSelector(state => state.webReducer);
  const {dataPopUp} = useSelector(state => state.popUpReducer);
  // const { getLocale } = useLocale();
  const [homeData, setHomeData] = useState([]);
  const {data} = dataHome;
  // const d = new Date("2023-1-8");
  useEffect(() => {
    if (Array.isArray(data)) {
      setHomeData(data[7]);
    }
  }, [data, homeData]);
  const settings = {
    arrows: false, // Disable navigation arrows
    bullets: true, // Enable pagination bullets
    search: (
      <Search data={Array.isArray(homeData.sections) && homeData.sections[0]} />
    ),
    slidesPerView: 1,
    loop: true,
    spaceBetween: 5,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  };
  return (
    <div className={`${styles.home} home`}>
      <div className={`${styles.positionRelative} landing`} id="swiperHome">
        {/* <div className={`${styles.positionAbsolute}`}>
          <h2>{getLocale("Expires in:")} <Countdown
            renderer={props => <><span style={{ fontSize: 26 }} > {props.days} <span className={styles.goldZodiac} >{getLocale("Days")}</span> {props.hours} <span className={styles.goldZodiac}>{getLocale("Hours")}</span>  {props.minutes}  <span className={styles.goldZodiac}>{getLocale("Minutes")}</span></span></>}
            date={d}
          /></h2>
          <div className={`${styles.cmlogo}`}>
            <img src={cmlogo} />
          </div>
          <img className={`${styles.homeHeadImg}`} src={homehead} alt="snow" />
        </div> */}
        <Swiper
          settings={settings}
          mySwiper={mySwiper}
          className={`${styles.headerFontSwipe}`}
        >
          {/* <div className={`${styles.swiperQuery} swiper-slide`}>
            <img src={homebgnew} alt="sliderImg" />
          </div>
           */}
          <div className="swiper-slide">
            <img src={slider1} alt="sliderImg" />
          </div>
          <div className="swiper-slide">
            <img src={slider2} alt="sliderImg" />
          </div>
          <div className="swiper-slide">
            <img src={slider3} alt="sliderImg" />
          </div>
        </Swiper>
        {/* <div className={`${styles.positionAbsoluteFoot}`}>
          <img src={homefoot} alt="snow" />
        </div> */}
      </div>
      {/* <Santa /> */}
      {dataPopUp && dataPopUp.length > 0 && (
        <div ref={popupRef} className="popUpSkiClupProgram">
          <PopUp ref={popupRef} data={dataPopUp[0]} />
        </div>
      )}
      <Offers data={Array.isArray(homeData.sections) && homeData.sections[1]} />
      <Save data={Array.isArray(homeData.sections) && homeData.sections[2]} />
      {/* <Save data={{ image: snowDay, mobile_image: snowDayMobile, url: "/snow-world-day", title: 'Register Now', ar_title: 'سجل الآن' }} /> */}
      <Packages
        data={Array.isArray(homeData.sections) && homeData.sections[3]}
      />
      <Video data={Array.isArray(homeData.sections) && homeData.sections[4]} />
      {/* <Select
                name="test"
                options={[{ value: "test", label: "test" }, { value: "test2", label: "test2" }, { value: "test3", label: "test3" }]}
                placeholder="Select an option"
            /> */}
      {/* <SkiClubProgram background={backgroundSki} data={SkiClubProgramData} click={chooseYourLesson} /> */}
      {/* <SocialSection /> */}
      {/* <OurAttractions/> */}
    </div>
  );
}
//import NewOffer from "./SubComponents/New Offer/NewOffer";

export default Home;
